<template>
  <div class="home-content flex flex-col h-full">
    <headerNav></headerNav>
    <main class="flex-1">
      <div class="main">
        <div
          class="dice-box"
          :class="{ 'dice-box-animation': isAnimation }"
          ref="Dice"
        >
          <div class="dice first-face">
            <span class="dot"></span>
          </div>
          <div class="dice second-face">
            <span class="dot"></span>
            <span class="dot"></span>
          </div>
          <div class="dice third-face">
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
          </div>
          <div class="dice fourth-face">
            <div class="column">
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
            <div class="column">
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
          </div>
          <div class="fifth-face dice">
            <div class="column">
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
            <div class="column">
              <span class="dot"></span>
            </div>
            <div class="column">
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
          </div>
          <div class="dice sixth-face">
            <div class="column">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
            <div class="column">
              <span class="dot"></span>
              <span class="dot"></span>
              <span class="dot"></span>
            </div>
          </div>
        </div>
        <el-input v-model="betAmount" placeholder="请输入投注金额" clearable />
        <div
          class="dice-btn"
          :class="{ 'dice-btn-disabled': isTransactionStatus }"
          @click="onChangePoint()"
        >
          {{ !isTransactionStatus ? "点击投注" : "交易正在进行中....." }}
        </div>
      </div>
    </main>
  </div>
  <el-dialog v-model="dialogVisible">
    <div class="text-center" v-if="transactionResult.won">
      <img class="transactionResult-img" src="@/assets/image/reward.png" />
    </div>
    <div class="text-center" v-else>
      <img class="transactionResult-img" src="@/assets/image/no_reward.png" />
      <div class="transactionResult-text">差一点就中奖了，再来一次吧!</div>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance, nextTick } from "vue";
import { useRouter } from "vue-router";
import headerNav from "@/components/headerNav.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import contractUtils from "@/utils/contractUtils.js";

const router = useRouter();

const Dice = ref();
const dialogVisible = ref(false);

// 筛子动画是否正在进行中
const isAnimation = ref(false);
// 是否正在交易
const isTransactionStatus = ref(false);
const betAmount = ref("");
// 交易结果
const transactionResult = ref({});

// 获取随机数
function getRandomInt(min, max) {
  min = Math.ceil(min); // 将最小值向上取整
  max = Math.floor(max); // 将最大值向下取整
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function onChangePoint() {
  if (!window.ethereum) {
    ElMessageBox.alert("请安装metamask", "提示", {
      // if you want to disable its autofocus
      // autofocus: false,
      confirmButtonText: "确定",
      callback: (action) => {
        window.open("https://metamask.io/", "_blank");
      },
    });
    return;
  }
  if (isAnimation.value || isTransactionStatus.value) {
    return;
  }
  var regNum = /^[0-9]+.?[0-9]*/; //判断是否是数字。
  if (
    !betAmount.value ||
    betAmount.value < 1 ||
    !regNum.test(betAmount.value)
  ) {
    ElMessage({ message: "投注金额不能小于1", type: "warning" });
    return;
  }
  // 调用函数获取1到6之间的随机整数
  let randomNumber = getRandomInt(0, 5);
  console.log(randomNumber);
  let randomArr = [
    // guess 骰子点数
    { direction: "Y", rotate: "0", guess: "1" },
    { direction: "Y", rotate: "90", guess: "2" },
    { direction: "X", rotate: "90", guess: "3" },
    { direction: "X", rotate: "270", guess: "4" },
    { direction: "Y", rotate: "270", guess: "5" },
    { direction: "Y", rotate: "180", guess: "6" },
  ];
  isAnimation.value = true;
  setTimeout(() => {
    let styleObj = randomArr[randomNumber];
    let rotate = `rotate${styleObj.direction}(${styleObj.rotate}deg)`;

    Dice.value.style.transform = rotate;
    isAnimation.value = false;
    setTimeout(() => {
      ElMessageBox.confirm(
        `投注金额为${betAmount.value}，投注点数为${styleObj.guess},确认投注？`,
        "提示",
        {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          submit(styleObj.guess, betAmount.value);
        })
        .catch(() => {});
    }, 500);
  }, 1000);
}
async function submit(guess, amount) {
  isTransactionStatus.value = true;
  try {
    let res = await contractUtils.send(guess, amount);
    if (res) {
      dialogVisible.value = true;
      transactionResult.value = res[0];
      console.log(transactionResult.value);
      isTransactionStatus.value = false;
    } else {
      isTransactionStatus.value = false;
    }
  } catch (err) {
    console.error(err);
    isTransactionStatus.value = false;
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/media.scss";
.home-content {
  background-image: url("~@/assets/image/homeBg.png"),
    linear-gradient(to right, rgb(131, 96, 195), rgb(46, 191, 145));
  overflow: hidden;
}
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: 100%;
  border-radius: 15px;
  position: relative;
  padding-top: 100px;
}

.dice-box {
  position: relative;
  transform-style: preserve-3d;
  transform: rotateY(185deg) rotateX(150deg) rotateZ(315deg);
  @include respond("mobile") {
    width: 100px;
    height: 100px;
  }
  @include respond("tablet") {
    width: 100px;
    height: 100px;
  }
  @include respond("pc") {
    width: 200px;
    height: 200px;
  }
}

.dice {
  padding: 20px;
  box-sizing: border-box;
  opacity: 1;
  background-color: white;
  position: absolute;
  border-radius: 15px;
  @include respond("mobile") {
    width: 100px;
    height: 100px;
  }
  @include respond("tablet") {
    width: 100px;
    height: 100px;
  }
  @include respond("pc") {
    width: 200px;
    height: 200px;
  }
}

.dot {
  display: inline-block;
  border-radius: 50%;
  background-color: #000;
  @include respond("mobile") {
    width: 25px;
    height: 25px;
  }
  @include respond("tablet") {
    width: 25px;
    height: 25px;
  }
  @include respond("pc") {
    width: 50px;
    height: 50px;
  }
}
.first-face {
  display: flex;
  justify-content: center;
  align-items: center;
}
.second-face {
  display: flex;
  justify-content: space-between;
}
.second-face .dot:nth-of-type(2) {
  align-self: flex-end;
}
.third-face {
  display: flex;
  justify-content: space-between;
}

.third-face .dot:nth-of-type(2) {
  align-self: center;
}

.third-face .dot:nth-of-type(3) {
  align-self: flex-end;
}
.fourth-face {
  display: flex;
  justify-content: space-between;
}
.fourth-face .column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fifth-face {
  display: flex;
  justify-content: space-between;
}

.fifth-face .column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fifth-face .column:nth-of-type(2) {
  justify-content: center;
}
.sixth-face {
  display: flex;
  justify-content: space-between;
}

.sixth-face .column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.first-face {
  @include respond("mobile") {
    transform: translateZ(50px);
  }
  @include respond("tablet") {
    transform: translateZ(50px);
  }
  @include respond("pc") {
    transform: translateZ(100px);
  }
}
.sixth-face {
  @include respond("mobile") {
    transform: translateZ(-50px);
  }
  @include respond("tablet") {
    transform: translateZ(-50px);
  }
  @include respond("pc") {
    transform: translateZ(-100px);
  }
}
.second-face {
  @include respond("mobile") {
    transform: translateX(-50px) rotateY(-90deg);
  }
  @include respond("tablet") {
    transform: translateX(-50px) rotateY(-90deg);
  }
  @include respond("pc") {
    transform: translateX(-100px) rotateY(-90deg);
  }
}
.fifth-face {
  @include respond("mobile") {
    transform: translateX(50px) rotateY(90deg);
  }
  @include respond("tablet") {
    transform: translateX(50px) rotateY(90deg);
  }
  @include respond("pc") {
    transform: translateX(100px) rotateY(90deg);
  }
}
.third-face {
  @include respond("mobile") {
    transform: translateY(50px) rotateX(90deg);
  }
  @include respond("tablet") {
    transform: translateY(50px) rotateX(90deg);
  }
  @include respond("pc") {
    transform: translateY(100px) rotateX(90deg);
  }
}
.fourth-face {
  @include respond("mobile") {
    transform: translateY(-50px) rotateX(90deg);
  }
  @include respond("tablet") {
    transform: translateY(-50px) rotateX(90deg);
  }
  @include respond("pc") {
    transform: translateY(-100px) rotateX(90deg);
  }
}

@keyframes rotate {
  10%,
  90% {
    transform: rotateY(45deg) rotateX(90deg) rotateZ(180deg);
  }
  20%,
  80% {
    transform: rotateY(360deg) rotateX(180deg) rotateZ(45deg);
  }
  30%,
  50%,
  70% {
    transform: rotateY(360deg) rotateX(180deg) rotateZ(45deg);
  }
  40%,
  60% {
    transform: rotateY(45deg) rotateX(90deg) rotateZ(180deg);
  }
}

.dice-box-animation {
  animation: rotate 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.dice-btn {
  cursor: pointer;
  display: block;
  
  @include respond("mobile") {
    margin: 50px auto;
  }
  @include respond("tablet") {
    margin: 50px auto;
  }
  @include respond("pc") {
   margin: 100px auto;
  }
  position: relative;
  background: #ff8100;
  border: none;
  color: white;
  padding: 15px 24px;
  font-size: 1.4rem;
  box-shadow: -6px 6px 0 hsl(35.48deg 87.72% 37.79%);
  outline: none;
  &:active {
    top: 3px;
    left: -3px;
    box-shadow: -3px 3px 0 hsl(38, 88%, 38%);
  }
  &::before {
    position: absolute;
    display: block;
    content: "";
    height: 0;
    width: 0;
    border: 6px solid transparent;
    border-right: 6px solid hsl(38, 88%, 38%);
    border-left-width: 0px;
    top: 0;
    left: -6px;
  }
  &::after {
    position: absolute;
    display: block;
    content: "";
    height: 0;
    width: 0;
    border: 6px solid transparent;
    border-top: 6px solid hsl(38, 88%, 38%);
    border-bottom-width: 0px;
    bottom: -6px;
    right: 0;
  }
}
.dice-btn-disabled {
  background: #bcbcbc;
  color: #000000;
  cursor: not-allowed;
  box-shadow: -6px 6px 0 #a5a5a6;
  &::before {
    border-right: 6px solid #a5a5a6;
  }
  &::after {
    border-top: 6px solid #a5a5a6;
  }
}
.transactionResult-img {
  margin: auto;
  width: 148px;
  height: 128px;
}
.transactionResult-text {
  color: #5d5e5e;
  margin-top: 20px;
}
::v-deep .el-input {
  @include respond("mobile") {
    margin-top: 100px;
    height: 40px;
    width: 300px;
  }
  @include respond("tablet") {
    margin-top: 100px;
    height: 40px;
    width: 300px;
  }
  @include respond("pc") {
    margin-top: 150px;
    height: 50px;
    width: 350px;
  }
}
</style>
