<template>
  <div class="header-warp">
    <!--  :class="isScroll? 'bg-white text-black border-bottom': 'bg-transparent text-white'" -->
    <div class="mx-auto w-full max-w-[2560px] px-4 sm:px-8 xxl:px-16 h-full">
      <nav class="Navbar--main items-center lg:py-1.5">
        <div class="header-left flex items-center">
          <div class="cursor-pointer flex items-center" @click="goPage('/')">
            <img class="logo-img" src="@/assets/image/logo.png" />
          </div>
          <div class="flex media-greaterThanOrEqual-md">
            <div class="eoiwrf">
              <div class="shrink-0 h-full w-[1px] mx-6 bg-white/20"></div>
            </div>
            <div class="flex items-center gap-8">
              <div class="cursor-pointer bYWTiF" @click="dialogVisible = true">
                <span class="text-base leading-6 font-semibold text-white"
                  >规则说明</span
                >
              </div>
              <!-- <div class="cursor-pointer bYWTiF">
                <span class="text-base leading-6 font-semibold">投注记录</span>
              </div> -->
            </div>
          </div>
        </div>
        <!-- <div class="header-center" :class="isScroll ? 'scroll' : 'default'">
          <el-input
            v-model="searchName"
            style="width: 460px"
            placeholder="搜索"
            :prefix-icon="Search"
          />
        </div> -->
        <!-- <div class="header-right">
          <ul class="header-right-ul">
            <li
              class="header-right-li cursor-pointer h-full items-center flex justify-center"
              :class="isScroll ? 'scroll-color' : 'default-color'"
              @click="signMessage()"
            >
              <svg
                fill="currentColor"
                height="24"
                role="img"
                viewBox="0 -960 960 960"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
                title="钱包"
              >
                <title>Wallet</title>
                <path
                  d="M240-160q-66 0-113-47T80-320v-320q0-66 47-113t113-47h480q66 0 113 47t47 113v320q0 66-47 113t-113 47H240Zm0-480h480q22 0 42 5t38 16v-21q0-33-23.5-56.5T720-720H240q-33 0-56.5 23.5T160-640v21q18-11 38-16t42-5Zm-74 130 445 108q9 2 18 0t17-8l139-116q-11-15-28-24.5t-37-9.5H240q-26 0-45.5 13.5T166-510Z"
                ></path>
              </svg>
              <div>登录</div>
            </li>
          </ul>
        </div> -->
      </nav>
    </div>
    <el-dialog v-model="dialogVisible" width="80%">
      <div class="text-base">
        <p class="font-semibold pb-2">规则说明：</p>
        <p class="pb-2">1:生成筛子随机数 1-6 （区块hash+区块时间戳+投注地址）</p>
        <p class="pb-2">2:投注金额限制 1-100000 dtt数量</p>
        <p class="pb-2">3:收取投注手续费10%</p>
        <p class="pb-2">4:投注点数 等于 生成筛子随机数 ；获胜=》获得2倍收益</p>
        <p class="pb-2">5:投注点数 不等于 生成筛子随机数 ；失败=》金额放入资金池</p>
        <p class="pb-2">6:投注金额不能超过资金池金额</p>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import Web3 from "web3";
import { onMounted, onBeforeUnmount, ref } from "vue";
import { useRouter } from "vue-router";
import { Calendar, Search } from "@element-plus/icons-vue";
import { defineProps } from "vue";
const router = useRouter();
const curProps = defineProps(["iswhite"]);
const dialogVisible = ref(false);

const searchName = ref("");
const isScroll = curProps.iswhite ? ref(true) : ref(false);
const accounts = ref([]);
onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

function handleScroll(event) {
  if (curProps.iswhite) {
    isScroll.value = true;
    return;
  }
  if (window.scrollY > 1) {
    isScroll.value = true;
  } else {
    isScroll.value = false;
  }
}

async function signMessage() {
  if (window.ethereum) {
    // 现代浏览器中的Web3提供者
    const web3 = new Web3(window.ethereum);
    try {
      // 请求账户访问权限
      await window.ethereum.enable();

      // 获取当前选定的账户
      const accounts = await web3.eth.getAccounts();
      const account = accounts[0];
      console.log("账户地址", account);
      // 要签名的消息（必须是十六进制的字符串）
      const message = "Hello, MetaMask!";
      const messageHex = web3.utils.utf8ToHex(message);
      console.log("消息", messageHex);
      // 使用MetaMask签名消息
      // 注意：web3.eth.personal.sign 是MetaMask扩展的API，不是标准的JSON-RPC
      const signature = await web3.eth.personal.sign(
        messageHex,
        account,
        "" // 最后一个参数是密码（对于MetaMask来说，这是不需要的）
      );

      console.log("Signature:", signature);
      const recoveredAddress = web3.eth.accounts.recover(messageHex, signature);
      console.log("恢复的地址：", recoveredAddress);
      // 验证恢复的地址是否与原始账户地址相同
      if (recoveredAddress.toLowerCase() === accounts[0].toLowerCase()) {
        console.log("签名验证成功");
      } else {
        console.log("签名验证失败");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  } else if (window.web3) {
    // 旧版浏览器中的Web3提供者
    console.log("Using the deprecated window.web3");
    // 类似上面的逻辑，但可能不支持所有功能
  } else {
    // 用户没有安装MetaMask或类似插件
    console.log("Please install MetaMask!");
  }
}

function goPage(path) {
  router.push({ path: path });
}
</script>
<style scoped lang="scss">
$typePoint: (
  "phone": (
    320px,
    480px,
  ),
  "pad": (
    481px,
    768px,
  ),
  "notebook": (
    769px,
    1024px,
  ),
  "desktop": (
    1025px,
    1200px,
  ),
  "tv": 1201px,
  "noPc": 1023px,
);

@mixin responseTo($type) {
  $bp: map-get($typePoint, $type);
  @if type-of($bp) == "list" {
    @media (min-width: nth($bp,1)) and (max-width: nth($bp,2)) {
      @content;
    }
  } @else if($type== "noPc") {
    @media (max-width: $bp) {
      @content;
    }
  } @else {
    @media (min-width: $bp) {
      @content;
    }
  }
}

.header-warp {
  --transition-curve: cubic-bezier(0.05, 0, 0.2, 1);
  transition: background-color 0.2s var(--transition-curve),
    box-shadow 0.2s var(--transition-curve), color 0.2s var(--transition-curve),
    transform 0.4s var(--transition-curve);
  border-bottom: none;
  max-width: 100vw;
  height: 72px;
  top: 0px;
  position: sticky;
  z-index: 150;
  .Navbar--main {
    display: flex;
    height: 100%;
    justify-content: space-between;
  }
  .header-left {
    padding-right: 0px;
    height: 100%;
    .logo-img {
      height: 60px;
    }
    .eoiwrf {
      height: 32px;
    }
    .bYWTiF {
      white-space: nowrap;
      background-color: transparent;
    }
  }
  .header-center {
    :deep(.el-input__wrapper) {
      line-height: 26px;
      padding: 8px;
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.12);
      color: #fff;
      backdrop-filter: blur(20px);
      transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1),
        border-color 0.2s cubic-bezier(0.05, 0, 0.2, 1);
      width: 460px;
      .el-input__prefix {
        font-size: 16px;
        color: #fff;
      }
      .el-input__inner {
        color: #fff;
      }
    }
  }
  .header-center.scroll {
    :deep(.el-input__wrapper) {
      background-color: rgba(18, 18, 18, 0.04);
      color: #000;
      .el-input__prefix {
        color: #000;
      }
      .el-input__inner {
        color: #000;
      }
    }
  }
  .header-right {
    &-li {
      border-radius: 12px;
      padding: 0px 12px;
      position: relative;
      height: 48px;
      min-width: 48px;
      border: 1px solid transparent;
      white-space: nowrap;
      backdrop-filter: blur(20px);
      transition: background-color 0.2s cubic-bezier(0.05, 0, 0.2, 1);
    }
  }
  .default-color {
    background-color: rgba(255, 255, 255, 0.12);
  }
  .scroll-color {
    background-color: rgba(18, 18, 18, 0.04);
  }
}
.border-bottom {
  border-bottom: 1px solid rgba(18, 18, 18, 0.08);
}
</style>
