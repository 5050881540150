import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/globals.css';

const app = createApp(App)


import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
app.use(ElementPlus)

import globalMethods from '@/utils/globalMethods';  
app.use(globalMethods)

import VConsole from 'vconsole';
const vConsole = new VConsole();

app.use(store).use(router).mount('#app')
