import Web3 from "web3";
import {  ElMessageBox } from "element-plus";
// 合约地址和ABI
const contractAddress = "0xcda8cc4b7249eee2f6fd0022ae3cbd311eedc304"; // 你需要查询的NFT合约地址
const contractABI = [
  {
    inputs: [
      {
        internalType: "uint8",
        name: "guess",
        type: "uint8",
      },
    ],
    name: "bet",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "player",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "Amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "betAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "guess",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint8",
        name: "result",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "bool",
        name: "won",
        type: "bool",
      },
    ],
    name: "BetPlaced",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "guess",
        type: "uint8",
      },
    ],
    name: "betTest",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_houseEdge",
        type: "uint256",
      },
    ],
    name: "setHouseEdge",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
  {
    inputs: [],
    name: "getBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "houseEdge",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "MAX_BET",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "MIN_BET",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
class contractConfig {
  constructor() {
    this.web3 = null;
    this.contract = null;
  }
  getInitData() {
    if (window.ethereum) {
      // this.web3 = new Web3(new Web3.providers.HttpProvider('http://13.212.23.35:6979'));
      this.web3 = new Web3(window.ethereum);
      this.contract = new this.web3.eth.Contract(contractABI, contractAddress)
      return true;
    } else {
      ElMessageBox.alert("请安装metamask", "提示", {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: "确定",
        callback: (action) => {
         window.open( "https://metamask.io/zh-CN/download/","_blank");
        }
      });
      return false;
    }
  }
  async send(guess, amount) {
    let plug = this.getInitData()
    if (plug) {
      let result = []
      let account = await this.getAccounts();
      let balance = await this.getBalance()
      console.log('account',amount)
      console.log('amount*2',amount*2)
      console.log('amount*2>balance',amount*2>balance)
      if(amount*2>balance){
        ElMessageBox.alert("投注金额超出奖励池最大金额", "提示", {
          confirmButtonText: "确定",
        });
        return false
      }
      // 将 ETH 转换为 Wei
      const valueInWei = this.web3.utils.toWei(amount.toString(), "ether");
      const gasPrice = await this.web3.eth.getGasPrice();  
      // 调用合约的 bet 函数并附带以太币转账
      const transaction = await this.contract.methods.bet(guess).send({
        from: account,
        value: valueInWei, // 发送的 ETH 数量（以 Wei 为单位）
        gasLimit : 210000,
        gasPrice:gasPrice, // 设置 gas 价格
      });
      const txHash = transaction.transactionHash; // 你的交易哈希
      const receipt = await this.web3.eth.getTransactionReceipt(txHash);
      if (receipt) {
        const eventSignature = this.web3.utils.sha3("BetPlaced(address,uint256,uint256,uint8,uint8,bool)");
        
        receipt.logs.forEach((log) => {
          if (log.topics[0] === eventSignature) {
            const eventData = this.web3.eth.abi.decodeLog(
              [
                { type: "address", name: "player", indexed: true },
                { type: "uint256", name: "Amount" },
                { type: "uint256", name: "betAmount" },
                { type: "uint8", name: "guess" },
                { type: "uint8", name: "result" },
                { type: "bool", name: "won" },
              ],
              log.data,
              log.topics.slice(1) // Remove the first topic (signature)
            );
            result.push(eventData)
            console.log("Parsed BetPlaced Event:", eventData);
          }
        });
      } else {
        console.log(
          "Transaction receipt not found, the transaction may still be pending."
        );
      }
      return result
    }
    return false;
  }
  async getAccounts() {
    // 请求 MetaMask 获取用户的账户
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    const account = accounts[0]; // 获取用户的第一个账户
    return account;
  }
  // 获取合约地址余额
  async getBalance() {
    // 请求 MetaMask 获取用户的账户
    const balance = await this.web3.eth.getBalance(contractAddress)
    console.log('合约地址余额',balance)
    const balanceInEther = this.web3.utils.fromWei(balance, 'ether');
    console.log('转义后的合约地址余额',balanceInEther)
    return balanceInEther;
  }


  
}

export default new contractConfig();
