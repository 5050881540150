import Web3 from 'web3';
const url = 'https://endpoints.omniatech.io/v1/bsc/testnet/public'
const web3 = new Web3(url);
export default class Web3Config {
  constructor() { }
  // 获取账户列表信息
  getAccounts(){
    return new Promise((resolve, reject) => {
      web3.eth.getAccounts().then((res) => {
        console.log('res-------------------------')
        console.log(res)
        resolve(res)
      })
    })
  }
  //获取账户余额 
  getBalance(balance) {
    return new Promise((resolve, reject) => {
      web3.eth.getBalance(balance).then((res) => {
        let balance = (BigInt(res)/BigInt(1e18))
        resolve(balance)
      })
    })
  }
  // 检查指定的字符串是否是有效的以太坊地址
  isAddress(address){
   return web3.utils.isAddress(address);
  }
  // 获取区块高度
  getBlockNumber() {
    return new Promise((resolve, reject) => {
      web3.eth.getBlockNumber().then((res) => { resolve(res) })
    })
  }
}

//查询交易web3.eth.getTransaction 拿到交易执行结果

//发送交易web3.eth.sendTransaction